import React from 'react'
import { graphql } from 'gatsby'

import { Link } from 'gatsby'
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout.js'
import { MapPin, Truck } from 'react-feather'
import './DeliveryPage.scss'


// Export Template for use in CMS preview
export const DeliveryPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body
}) => (
  <main>
    
    <PageHeader
      title={title}
      subtitle={subtitle}
      className="delivery--header"
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <ul className="points points--delivery">
          <li>
            <div className="point--Content">
            <MapPin />
              <h3 className="point--Title"><Link to='/delivery-areas/central-coast'>Central Coast deliveries</Link></h3>
              <div className="point--Excerpt">Gosford, Wyong, Erina, Terrigal, Kincumber, Woy Woy, Kariong, Tuggerah, Green Point, Blue Haven, The Entrance, Toukley, Somersby and more...</div>
            </div>
          </li>
          <li>
            <div className="point--Content">
            <MapPin />
              <h3 className="point--Title"><Link to='/delivery-areas/sydney'>Sydney deliveries</Link></h3>
              <div className="point--Excerpt">Hornsby, Chatswood, Northern Suburbs, Eastern Suburbs, Southern Suburbs, Western Suburbs, Parramatta, Ryde, Sydney CBD and more...</div>
            </div>
          </li>
          <li>
            <div className="point--Content">
            <MapPin />
              <h3 className="point--Title"><Link to='/delivery-areas/newcastle'>Newcastle deliveries</Link></h3>
              <div className="point--Excerpt">Charlestown, Belmont, Merewether, Kahibah, Hexham, Newcastle East, Newcastle West, Bar Beach, Hamilton, Warners Bay, Georgetown, Lambton, Maryville, Newcastle CBD and more...</div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section className="section">
      <div className="container">
      <h1 className="text-center mb-5">DELIVERIES AVAILABLE</h1>
        <ul className="points points--delivery">
          <li>
            <div className="point--Content">
            <Truck />
              <h3 className="point--Title"><Link to='/delivery-areas/sydney'>SYDNEY</Link></h3>
              <div className="point--Excerpt">Pickup/deliver to &amp; from Sydney &hellip; Monday, Tuesday, Wednesday, Thursday &amp; Friday each week except for public holidays</div>
            </div>
          </li>
          <li>
            <div className="point--Content">
            <Truck />
              <h3 className="point--Title"><Link to='/delivery-areas/central-coast'>CENTRAL COAST</Link></h3>
              <div className="point--Excerpt">Pickup/deliver to &amp; from the Central Coast &hellip; Monday, Tuesday, Wednesday, Thursday &amp; Friday each week except for public holidays</div>
            </div>
          </li>
          <li>
            <div className="point--Content">
            <Truck />
              <h3 className="point--Title"><Link to='/delivery-areas/newcastle'>NEWCASTLE</Link></h3>
              <div className="point--Excerpt">Pickup/deliver to &amp; from Newcastle &hellip; Tuesday &amp; Friday each week, PICKUP DAY BEFORE except for public holidays</div>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section className="section text-center">
      <div className="container">
        <h2>NOT SURE IF YOU’RE IN OUR DELIVERY AREA?</h2>
        <p>Get in touch. We can put together a tailored transportation package just for you or point you in the right direction.</p>
        <Link to="/get-in-touch/" className="Button">
            Contact us
        </Link>
      </div>
    </section>
  </main>
)

const DeliveryPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <DeliveryPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default DeliveryPage

export const pageQuery = graphql`
  query DeliveryPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
      }
    }
  }
`
